import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
export function gsapBall(elem) {
  if (elem) {
    gsap.set(elem, { xPercent: -50, yPercent: -50 })
    //   console.log(elem)
    const ball = elem
    const pos = {
      x: window.innerWidth * Math.floor(Math.random() * 1 > 0.5 ? 2 : -2),
      y: Math.floor(Math.random() * (window.innerHeight / 2)),
    }
    const mouse = { x: pos.x, y: pos.y }
    var speedX = 0.1
    var speedY = 0.2
    //

    const xSet = gsap.quickSetter(ball, "x", "px")
    const ySet = gsap.quickSetter(ball, "y", "px")

    let tl = gsap.timeline({
      repeat: -1,
      ease: "elastic",
    })

    tl.to(elem, { autoAlpha: 1, rotation: -5, duration: 0.7 }).to(elem, {
      rotation: 5,
      duration: 0.6,
      yoyo: true,
    })

    let clearTime
    window.addEventListener("mousemove", e => {
      mouse.x = e.x
      mouse.y = e.y

      clearTimeout(clearTime)
      tl.play()

      clearTime = setTimeout(() => {
        gsap.to(elem, { autoAlpha: 0, duration: 0.4 })
        tl.pause()
      }, 600)
    })

    gsap.ticker.add(() => {
      pos.x += (mouse.x - pos.x) * speedX
      pos.y += (mouse.y - pos.y) * speedY
      xSet(pos.x)
      ySet(pos.y)
    })
  }
}

export function gsapScrollTrigger(
  elem,
  x = 100,
  y = 0,
  width,
  skew = 0,
  delay
) {
  gsap.registerPlugin(ScrollTrigger)

  if (width && elem) {
    let startingPoint = width < 641 ? "top 90%" : "top 90%"

    let widthDelay = width < 641 ? 0 : delay

    gsap.set(elem, { y: y, x: x, autoAlpha: 0, skewX: skew })
    ScrollTrigger.batch(elem, {
      start: startingPoint,
      end: "top 15%",
      // markers: true,
      onEnter: (elements, triggers) => {
        gsap.to(elements, {
          autoAlpha: 1,
          x: 0,
          y: 0,
          skewX: 0,
          delay: widthDelay,
          stagger: 0.45,
          duration: 1,
          ease: "power3.out",
          // ease: "elastic.out(1, 0.5)",
        })
        // console.log(elements.length, "elements entered")
        // console.log(triggers, "triggers")
      },
      // onLeave: (elements, triggers) => {
      //   gsap.to(elements, { opacity: 0, y: y, x: x, stagger: 0.35 })
      //   console.log(elements.length, "elements left")
      // },
    })
  }
}

export function scrollInNatur(elem, x = 0, y = 0, scale = 1) {
  gsap.registerPlugin(ScrollTrigger)

  // console.log(elem)
  if (elem) {
    gsap.set(elem, { scale: scale })
    ScrollTrigger.batch(elem, {
      start: "top 65%",
      // end: "top 0%",
      markers: false,

      onEnter: (elements, triggers) => {
        gsap.to(elements, {
          autoAlpha: 1,
          x: 0,
          y: 0,
          scale: 1,
          // stagger: 0.25,
          duration: 0.3,
          ease: "expo.in",
          // ease: "elastic.out(1, 0.5)",
        })
        //   console.log(elements.length, "elements entered")
        //   console.log(triggers, "triggers")
      },
      // onLeave: (elements, triggers) => {
      //   gsap.to(elements, { opacity: 0, x: x, stagger: 0.35 })
      //   console.log(elements.length, "elements left")
      // },
      onComplete: () => ScrollTrigger.refresh(),
    })
  }
}

export function noTriggerFx(elem, x = 0, y = 0, scale = 1, opacity = 1) {
  gsap.set(elem, { x: x })

  let tl = gsap.timeline({ repeat: 0 })
  tl.to(elem, {
    delay: 0.2,
    autoAlpha: opacity,
    duration: 0.6,
    stagger: 0.2,
    x: 0,
  })
}

export function gsapDevicesScrollTrigger(elem) {
  gsap.registerPlugin(ScrollTrigger)

  if (elem) {
    // gsap.set(elem, { x: 0, y: 0, rotate: 0 })

    // let startHeight = -200
    let rotate = 90

    //MASTER
    let masterTimeline = gsap.timeline({
      repeat: false,
      ease: "none",
      autoRemoveChildren: true,
      scrollTrigger: {
        trigger: elem,
        start: "top 65%",
      },
    })
    //MASTER
    //
    ///// //ONCE
    // let tl1 = gsap
    //   .timeline({ repeat: false, ease: "none", yoyo: true })
    //   .to(elem, {
    //     x: -200,
    //     y: startHeight,
    //     rotate: 0,
    //     duration: 1,
    //     repeat: false,
    //     onComplete: () => {
    //       // tlOnce.kill()
    //     },
    //   })
    ////// // // ONCE
    //

    //LOOP
    let tl2 = gsap
      .timeline({
        repeat: -1,
        ease: "none",
        yoyo: true,
      })
      .to(elem, {
        x: 0,
        y: 0,
        rotate: 0,
        // duration: 1,
        ease: "none",
      })
      .to(elem, { x: 0, y: 0, rotate: rotate + 5, duration: 1, ease: "none" })
      .to(elem, {
        x: 0,
        y: 0,
        rotate: rotate - 3,
        // duration: 1,
        ease: "none",
      })
      .to(elem, {
        x: 0,
        y: 0,
        rotate: rotate + 12,
        // duration: 1,
        ease: "none",
      })
      .to(elem, {
        x: 0,
        y: 0,
        rotate: rotate - 9,
        // duration: 1,
        ease: "none",
      })

    //LOOP
    //

    masterTimeline.add(tl2)
  }
}
